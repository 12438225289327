import React from 'react'
import { Box, Text } from 'rebass/styled-components'

const Error404 = () => {
    return (
        <Box>
            <Text as='p'>Uh Oh, this page doesn't exist.</Text>
        </Box>
    )
}

export default Error404
